.buttonColorFormItemContainer {
  display: flex;
  gap: 10px;
}

.colorIndicator {
  width: 40px;
  align-self: stretch;
  border-radius: 3px;
}

@primary-color: #0000FF;