@import '~antd/dist/antd.less';

/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Override antd class styles */
.ant-btn-sm {
  height: 22px;
}
.ant-btn-link.ant-btn-sm {
  border-width: 0;
  padding: 0;
}
.ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-content {
  padding-left: 20px;
}
.ant-modal-body .ant-form-item:last-child {
  margin-bottom: 0;
}

@primary-color: #0000FF;