.bidderCard {
  .bidderActions {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover .bidderActions {
    visibility: visible;
    opacity: 1;
  }
}

@primary-color: #0000FF;