.deleteField {
  cursor: pointer;
  position: relative;
  margin-left: 8px;
  font-size: 20px;
  color: #999;
  transition: all 0.3s;
}
.deleteField:hover {
  color: #777;
}
.deleteField[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

@primary-color: #0000FF;