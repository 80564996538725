@import '~antd/dist/antd.less';

.metricsCard {
  :global(.ant-card-body) {
    padding: 16px;
  }

  :global(.ant-skeleton-title) {
    height: 23px; // Set loading placeholder height to more accurately match the eventual value's height
  }

  .metricsTooltips {
    position: absolute;
    top: 18px;
    right: 16px;
  }

  .metricsHelpTooltip {
    margin-inline-start: 0;
  }

  .statistic {
    :global(.ant-statistic-content) {
      font-size: 30px;
      font-weight: 300;
    }
  }

  .graph {
    padding-top: 16px;
  }

  .table {
    padding-top: 12px;
  }
}

// Aligns height of Daily Breakdown & Payable Revenue table cards to line up with the Top Websites card when it appears to the left of them
@media (min-width: @screen-xl) {
  .dailyBreakdownCard {
    min-height: 330px;
  }
  .payableRevenueCard {
    min-height: 240px;

    // Ensure height of table when loading doesn't go past the Top Websites card
    :global(.ant-empty-normal) {
      margin: 8px 0;
    }
  }
}

@primary-color: #0000FF;