@import '~antd/dist/antd.less';

.container > :global(.ant-card-body) {
  padding: 0;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.toolbarTitle {
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
  margin: 10px 0;
}

.toolbarActions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search {
  width: 300px;
  margin: 10px 0;
}

.selectionBar {
  height: 50px;
  margin: 0 20px 20px;

  // Override default Ant Design <Alert> close text style to look like a button link
  :global(.ant-alert-close-text) {
    .btn-link();
  }
}
.selectionActions {
  margin-right: 8px;
}

.table :global(.ant-btn-link) {
  height: auto;
  padding: 0 10px;
}
.table :global(.ant-btn-link.ant-btn-sm) {
  padding: 0;
}

.table
  :global(.ant-table-thead)
  tr:first-child
  > :global(th:first-child:not(.ant-table-expand-icon-col):not(.ant-table-selection-column)),
.table
  :global(.ant-table-tbody
    .ant-table-row
    td.ant-table-cell:first-child:not(.ant-table-row-expand-icon-cell):not(.ant-table-selection-column)) {
  padding-left: 24px;
}
.table :global(.ant-table-thead .ant-table-column-sorters) {
  padding: 0;
}
.table :global(.ant-table-footer),
.table :global(.ant-table-thead) > tr > th,
.table :global(.ant-table-tbody) > tr > td,
.table tfoot > tr > th,
.table tfoot > tr > td {
  padding: 14px;
}
.table :global(.ant-table-pagination.ant-pagination) {
  padding: 0 12px;
}

/* Override default expand & selection column widths */
:global(.ant-table .ant-table-expand-icon-col) {
  width: 45px; /* (from 48px default) */
}
:global(.ant-table .ant-table-selection-col) {
  width: 48px; /* (from 60px default) */
  min-width: 48px; /* Ensure width persists in smaller resolutions */
}
.table :global(.ant-table-cell.ant-table-selection-column) {
  padding: 0 3px;
}

/* Reduce padding between expand & selection cells when both columns are present in the table */
:global(.ant-table-expand-icon-col + .ant-table-selection-col) {
  width: 32px;
  min-width: 32px; /* Ensure width persists in smaller resolutions */
}
:global(.ant-table-row-expand-icon-cell + .ant-table-cell.ant-table-selection-column) {
  text-align: left;
  padding-left: 0;
}

@primary-color: #0000FF;