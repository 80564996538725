@import '~antd/dist/antd.less';

.dimensionsHeader {
  padding-bottom: 8px;
  border: none;
}

.reportBuilder {
  display: flex;

  .leftSection {
    width: 320px;
    flex-shrink: 0;
  }

  // Override default Ant Design styles
  label {
    font-weight: 600;
  }
  :global(.ant-table-cell.ant-table-selection-column) {
    // Remove right border of select checkbox column
    border-right: none !important;
  }
}

.reportDimensions {
  display: flex;
  margin-top: 16px;
}

.arrowContainer {
  margin: 60px 30px;
}

.availableReportDimensions,
.selectedReportDimensions {
  min-width: 300px;
}

.reportBuilderFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  background: white;
  box-shadow: 0px -1px 12px rgba(0, 21, 41, 0.08);
  padding: 16px;

  display: flex;
  justify-content: flex-end;
}

.scheduleDays {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .dayLabel {
    display: block;
    border: 1px solid #adadad;
    border-radius: 6px;
    width: 42px;
    color: #adadad;
    text-align: center;
    line-height: 32px;
    user-select: none;
    cursor: pointer;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .dayLabel {
    border: 1px solid @info-color-deprecated-border;
    color: @info-color;
    background-color: @info-color-deprecated-bg;
  }
}

@primary-color: #0000FF;