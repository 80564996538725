.expandedContentContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  .expandedContentItem {
    align-items: center;
    margin: 0px 12px;
    padding: 8px 0px;
    border-bottom: 1px solid #d9d9d9;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ellipsisText {
      max-width: 150px;
      @media (min-width: 1500px) {
        width: 175px;
      }
      @media (min-width: 1700px) {
        width: 200px;
      }
    }
  }
}

@primary-color: #0000FF;