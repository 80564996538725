.scopePageForm {
  display: flex;
  column-gap: 32px;
  row-gap: 4px;

  // Remove .ant-form-item margins (since we have column-gap & row-gap on .scopePageForm)
  :global(.ant-form-item) {
    margin-right: 0;
  }
}

@primary-color: #0000FF;