.navbar {
  background-color: #fff;
  padding: 0;
  z-index: 1;
}
.navbarContainer {
  background-color: rgba(0, 0, 0, 0.015);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  padding: 0 16px;
  position: relative;
}
.navbarItem {
  display: flex;
  align-items: center;
  padding: 0 12px;
  transition: background-color 0.3s;
}
.navbarItem:not(.noHover):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.userDropdown :global(.ant-dropdown-menu-item) {
  min-width: 125px;
}

.userEmail:hover {
  cursor: default;
}

.userAvatarSkeleton {
  height: 48px;
  width: 120px;
}
.userAvatarSkeleton > * {
  vertical-align: middle;
  line-height: 0;
}
.userAvatarSkeleton :global(.ant-skeleton-title) {
  margin: 0 !important;
}

@primary-color: #0000FF;