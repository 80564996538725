@import '~antd/dist/antd.less';

.helpTooltipIcon {
  // Grab styles from antd's `.@{form-item-prefix-cls}-tooltip`
  color: @text-color-secondary;
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: @margin-xss;
}

@primary-color: #0000FF;