.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;

  .name {
    font-size: 14px;
    font-weight: bold;
  }

  .stats {
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;
    padding-top: 5px;

    .stats-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      border-bottom: 1px solid #ccc;

      :first-child {
        font-weight: bold;
      }
    }
  }
}

@primary-color: #0000FF;