@import '~antd/dist/antd.less';

.dragAndDropTable {
  min-height: 250px;
  min-width: 300px;
  padding: 8px 8px 0; // Remove bottom padding to accommodate <tr>'s bottom margin
  border: 1px solid @table-border-color;
  border-radius: @table-border-radius-base;
  th,
  thead {
    display: none;
  }
  tbody {
    border: 0;
  }
  tr {
    display: flex;
    align-items: center;
    background-color: @table-bg;
    border: 1px solid @normal-color;
    border-radius: @table-border-radius-base;
    margin-bottom: 8px;

    &.isDragging {
      background-color: @table-row-hover-bg;
    }

    &:global([data-rbd-placeholder-context-id]) {
      border: none; // hide appearance of <Droppable>'s <tr> placeholder
    }
  }
  td {
    flex-grow: 1;
    border-bottom: 0 !important;

    &:global(.drag-icon),
    &:global(.close-action) {
      flex-grow: 0;
      flex-shrink: 1;
    }
    &:global(.drag-icon) {
      width: 30px;
      height: 38px; // ensure height is filled for fixed dimensions (ones that can't be rearranged)
    }
  }
}

@primary-color: #0000FF;