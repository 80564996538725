@import '~antd/dist/antd.less';

.errorMessage {
  height: 100%;
  padding: 0 20px 80px;
}

.errorRedirect {
  background: @layout-body-background;
  min-height: 100vh;
}

@primary-color: #0000FF;